/* Mixins */
/* Imports */
@font-face {
  font-family: Entypo;
  src: url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.eot) format("eot"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.woff) format("woff"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.ttf) format("truetype");
}
#app h1 {
  margin: .825em 0;
}
#app h1.thin {
    font-weight: normal !important;
}
#app h2.title {
  font-size: 1.5em;
  margin: .825em 0;
}
#app h2.title.thin {
    font-weight: normal !important;
}
#app h3 {
  font-size: 1.17em;
  margin: .825em 0;
}
#app h3.thin {
    font-weight: normal !important;
}
#app p {
  font-size: 1em;
}
#app a.btn, #app a.btn-link {
  cursor: pointer;
}
#app a.btn:hover, #app a.btn-link:hover {
    text-decoration: none;
}
.rrpxtend .main-content p {
  margin: 1em 0;
}
#app {
  /* <a> Links */
}
#app button.button {
    border: none;
}
#app .icon {
    background: transparent;
    border: none;
    margin: 0;
    cursor: pointer;
}
#app .icon img {
      vertical-align: middle;
      max-width: 25px;
}
#app .icon.large img {
      max-width: 25px;
}
#app .icon.medium img {
      max-width: 20px;
}
#app .icon.small img {
      max-width: 15px;
}
#app .button {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    padding: .5em 2em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: bold;
    font-style: italic;
    font-size: .80em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}
#app .button p {
      display: inline-block;
      -ms-flex-item-align: center;
          align-self: center;
      margin: 0;
}
#app .button:hover {
      text-decoration: none !important;
}
#app .button.small {
      height: 30px;
}
#app .button.medium {
      height: 40px;
}
#app .button.large {
      height: 50px;
}
#app .button.arrow.before {
      padding-left: 3em;
}
#app .button.arrow.after {
      padding-right: 3em;
}
#app .button.long {
      padding-left: 3em;
      padding-right: 3em;
}
#app .button.long.before {
        padding-left: 3.5em;
}
#app .button.long.after {
        padding-right: 3.5em;
}
#app .button.tall {
      padding-top: .75em;
      padding-bottom: .75em;
}
#app .button.grey {
      color: #555;
      background-color: #d9d7d7;
}
#app .button.red {
      color: #ffffff;
      background-color: #a00530;
}
#app .button.red:hover {
        background-color: #B70037;
}
#app .button.dark {
      color: #ffffff;
      background-color: #231f20;
}
#app .button.dark:hover {
        background-color: #393536;
}
#app .button.arrow.after:after {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      right: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.after.grey:after {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-dark.png);
}
#app .button.arrow.before:before {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      left: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.before.grey:before {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-dark.png);
}
#app .link {
    cursor: pointer;
    color: #2f2e2e;
}
#app .link.arrow.after:after {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-left: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.arrow.before:before {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-right: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.icon {
      text-decoration: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}
/* Inputs */
#app select {
  width: 100%;
}
/* vue-select styling */
#app .v-select {
  border: 1px solid #a8a8a9;
  background: transparent;
  color: #ffffff;
}
#app .v-select.unsearchable input[type=search] {
    opacity: 1 !important;
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 0 !important;
}
#app .v-select:after {
    content: none;
}
#app .v-select.has-error input[type=search]::-webkit-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search]:-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search]::-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search]:-ms-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select input[type=search]::-webkit-input-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search]:-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search]::-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search]:-ms-input-placeholder {
    color: #d6d8dc;
}
#app .v-select .selected-tag {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 1 !important;
    border: none;
    padding: 0 10px;
    margin: 0;
    width: 100%;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
#app .v-select .dropdown-toggle {
    height: 100%;
    border: none;
    padding: 0;
}
#app .v-select .dropdown-toggle .vs__selected-options {
      padding: 0;
}
#app .v-select .dropdown-toggle input {
      margin: 0;
}
#app .v-select .dropdown-menu {
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    border-left: 1px solid #f2f2f3;
    border-bottom: 1px solid #f2f2f3;
    border-right: 1px solid #f2f2f3;
    overflow-y: auto !important;
    z-index: 999;
    margin: 0;
    right: 0;
}
#app .v-select button.clear {
    display: none;
}
.v-select .dropdown-menu li {
  margin: 0 !important;
}
.v-select .dropdown-menu li:not(:last-of-type) {
    border-bottom: 1px solid #a8a8a9;
}
.v-select .dropdown-menu li.highlight a {
    background: #a00530;
    color: #ffffff;
}
.v-select .dropdown-menu li a {
    padding: 5px 10px;
    color: #2f2e2e;
}
.v-select .dropdown-menu li a:hover {
      text-decoration: none !important;
}
#app .box {
  background-color: #ffffff;
}
#app .box.grey,
#app .bg-ltgrey .box.grey {
  background-color: #ebeef3;
}
#app .box.grey-dark,
#app .bg-ltgrey .box.grey-dark {
  background-color: #d6d8dc;
}
#app .bg-ltgrey .box,
#app .box.trans {
  background-color: transparent;
}
#app .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .flex.split {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
#app .flex.center.horizontal {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#app .flex.center.vertical {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
#app .flex.wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#app .flex.wrap.reverse {
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
}
.rrpxtend #wrapper #main > .inner {
  padding: 0 !important;
}
/* Core */
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app .bordered.full {
    border: 1px solid;
}
#app .bordered.top {
    border-top: 5px solid;
}
#app .bordered.red {
    border-color: #a00530;
}
#app .bordered.grey {
    border-color: #ebeef3;
}
#app .bordered.grey.dark {
      border-color: #d6d8dc;
}
#app .color.red {
    color: #a00530;
}
#app .floated {
    position: absolute;
}
#app .floated.top {
      top: 1em;
}
#app .floated.right {
      right: 1em;
}
#app .section-separator {
    border-width: 3px;
    border-style: solid;
    border-color: #ebeef3;
}
.app-page {
  text-align: left;
}
.app-page .headline {
    padding: 2em 0;
    margin: 0;
}
.app-page .headline h1, .app-page .headline p {
      margin-top: 0 !important;
}
.app-page .app-content {
    width: 100%;
    margin: 0 auto;
}
.app-page .app-content .box-content {
      width: 90%;
      margin: 0 auto;
      padding: 1.5em 0;
}
/* Mixins */
/* Imports */
@font-face {
  font-family: Entypo;
  src: url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.eot) format("eot"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.woff) format("woff"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.ttf) format("truetype");
}
#app h1[data-v-189b2a9c] {
  margin: .825em 0;
}
#app h1.thin[data-v-189b2a9c] {
    font-weight: normal !important;
}
#app h2.title[data-v-189b2a9c] {
  font-size: 1.5em;
  margin: .825em 0;
}
#app h2.title.thin[data-v-189b2a9c] {
    font-weight: normal !important;
}
#app h3[data-v-189b2a9c] {
  font-size: 1.17em;
  margin: .825em 0;
}
#app h3.thin[data-v-189b2a9c] {
    font-weight: normal !important;
}
#app p[data-v-189b2a9c] {
  font-size: 1em;
}
#app a.btn[data-v-189b2a9c], #app a.btn-link[data-v-189b2a9c] {
  cursor: pointer;
}
#app a.btn[data-v-189b2a9c]:hover, #app a.btn-link[data-v-189b2a9c]:hover {
    text-decoration: none;
}
.rrpxtend .main-content p[data-v-189b2a9c] {
  margin: 1em 0;
}
#app[data-v-189b2a9c] {
  /* <a> Links */
}
#app button.button[data-v-189b2a9c] {
    border: none;
}
#app .icon[data-v-189b2a9c] {
    background: transparent;
    border: none;
    margin: 0;
    cursor: pointer;
}
#app .icon img[data-v-189b2a9c] {
      vertical-align: middle;
      max-width: 25px;
}
#app .icon.large img[data-v-189b2a9c] {
      max-width: 25px;
}
#app .icon.medium img[data-v-189b2a9c] {
      max-width: 20px;
}
#app .icon.small img[data-v-189b2a9c] {
      max-width: 15px;
}
#app .button[data-v-189b2a9c] {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    padding: .5em 2em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: bold;
    font-style: italic;
    font-size: .80em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}
#app .button p[data-v-189b2a9c] {
      display: inline-block;
      -ms-flex-item-align: center;
          align-self: center;
      margin: 0;
}
#app .button[data-v-189b2a9c]:hover {
      text-decoration: none !important;
}
#app .button.small[data-v-189b2a9c] {
      height: 30px;
}
#app .button.medium[data-v-189b2a9c] {
      height: 40px;
}
#app .button.large[data-v-189b2a9c] {
      height: 50px;
}
#app .button.arrow.before[data-v-189b2a9c] {
      padding-left: 3em;
}
#app .button.arrow.after[data-v-189b2a9c] {
      padding-right: 3em;
}
#app .button.long[data-v-189b2a9c] {
      padding-left: 3em;
      padding-right: 3em;
}
#app .button.long.before[data-v-189b2a9c] {
        padding-left: 3.5em;
}
#app .button.long.after[data-v-189b2a9c] {
        padding-right: 3.5em;
}
#app .button.tall[data-v-189b2a9c] {
      padding-top: .75em;
      padding-bottom: .75em;
}
#app .button.grey[data-v-189b2a9c] {
      color: #555;
      background-color: #d9d7d7;
}
#app .button.red[data-v-189b2a9c] {
      color: #ffffff;
      background-color: #a00530;
}
#app .button.red[data-v-189b2a9c]:hover {
        background-color: #B70037;
}
#app .button.dark[data-v-189b2a9c] {
      color: #ffffff;
      background-color: #231f20;
}
#app .button.dark[data-v-189b2a9c]:hover {
        background-color: #393536;
}
#app .button.arrow.after[data-v-189b2a9c]:after {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      right: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.after.grey[data-v-189b2a9c]:after {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-dark.png);
}
#app .button.arrow.before[data-v-189b2a9c]:before {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      left: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.before.grey[data-v-189b2a9c]:before {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-dark.png);
}
#app .link[data-v-189b2a9c] {
    cursor: pointer;
    color: #2f2e2e;
}
#app .link.arrow.after[data-v-189b2a9c]:after {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-left: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.arrow.before[data-v-189b2a9c]:before {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-right: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.icon[data-v-189b2a9c] {
      text-decoration: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}
/* Inputs */
#app select[data-v-189b2a9c] {
  width: 100%;
}
/* vue-select styling */
#app .v-select[data-v-189b2a9c] {
  border: 1px solid #a8a8a9;
  background: transparent;
  color: #ffffff;
}
#app .v-select.unsearchable input[type=search][data-v-189b2a9c] {
    opacity: 1 !important;
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 0 !important;
}
#app .v-select[data-v-189b2a9c]:after {
    content: none;
}
#app .v-select.has-error input[type=search][data-v-189b2a9c]::-webkit-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-189b2a9c]:-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-189b2a9c]::-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-189b2a9c]:-ms-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select input[type=search][data-v-189b2a9c]::-webkit-input-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-189b2a9c]:-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-189b2a9c]::-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-189b2a9c]:-ms-input-placeholder {
    color: #d6d8dc;
}
#app .v-select .selected-tag[data-v-189b2a9c] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 1 !important;
    border: none;
    padding: 0 10px;
    margin: 0;
    width: 100%;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
#app .v-select .dropdown-toggle[data-v-189b2a9c] {
    height: 100%;
    border: none;
    padding: 0;
}
#app .v-select .dropdown-toggle .vs__selected-options[data-v-189b2a9c] {
      padding: 0;
}
#app .v-select .dropdown-toggle input[data-v-189b2a9c] {
      margin: 0;
}
#app .v-select .dropdown-menu[data-v-189b2a9c] {
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    border-left: 1px solid #f2f2f3;
    border-bottom: 1px solid #f2f2f3;
    border-right: 1px solid #f2f2f3;
    overflow-y: auto !important;
    z-index: 999;
    margin: 0;
    right: 0;
}
#app .v-select button.clear[data-v-189b2a9c] {
    display: none;
}
.v-select .dropdown-menu li[data-v-189b2a9c] {
  margin: 0 !important;
}
.v-select .dropdown-menu li[data-v-189b2a9c]:not(:last-of-type) {
    border-bottom: 1px solid #a8a8a9;
}
.v-select .dropdown-menu li.highlight a[data-v-189b2a9c] {
    background: #a00530;
    color: #ffffff;
}
.v-select .dropdown-menu li a[data-v-189b2a9c] {
    padding: 5px 10px;
    color: #2f2e2e;
}
.v-select .dropdown-menu li a[data-v-189b2a9c]:hover {
      text-decoration: none !important;
}
#app .box[data-v-189b2a9c] {
  background-color: #ffffff;
}
#app .box.grey[data-v-189b2a9c],
#app .bg-ltgrey .box.grey[data-v-189b2a9c] {
  background-color: #ebeef3;
}
#app .box.grey-dark[data-v-189b2a9c],
#app .bg-ltgrey .box.grey-dark[data-v-189b2a9c] {
  background-color: #d6d8dc;
}
#app .bg-ltgrey .box[data-v-189b2a9c],
#app .box.trans[data-v-189b2a9c] {
  background-color: transparent;
}
#app .flex[data-v-189b2a9c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .flex.split[data-v-189b2a9c] {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
#app .flex.center.horizontal[data-v-189b2a9c] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#app .flex.center.vertical[data-v-189b2a9c] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
#app .flex.wrap[data-v-189b2a9c] {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#app .flex.wrap.reverse[data-v-189b2a9c] {
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
}
.rrpxtend #wrapper #main > .inner[data-v-189b2a9c] {
  padding: 0 !important;
}
/* Core */
#app[data-v-189b2a9c] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app .bordered.full[data-v-189b2a9c] {
    border: 1px solid;
}
#app .bordered.top[data-v-189b2a9c] {
    border-top: 5px solid;
}
#app .bordered.red[data-v-189b2a9c] {
    border-color: #a00530;
}
#app .bordered.grey[data-v-189b2a9c] {
    border-color: #ebeef3;
}
#app .bordered.grey.dark[data-v-189b2a9c] {
      border-color: #d6d8dc;
}
#app .color.red[data-v-189b2a9c] {
    color: #a00530;
}
#app .floated[data-v-189b2a9c] {
    position: absolute;
}
#app .floated.top[data-v-189b2a9c] {
      top: 1em;
}
#app .floated.right[data-v-189b2a9c] {
      right: 1em;
}
#app .section-separator[data-v-189b2a9c] {
    border-width: 3px;
    border-style: solid;
    border-color: #ebeef3;
}
@media only screen and (min-width: 1400px) {
.app-mini-finder[data-v-189b2a9c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
}
a.btn-link[data-v-189b2a9c] {
  font-size: 1rem !important;
  font-weight: normal;
}
.nowrap[data-v-189b2a9c] {
  white-space: nowrap;
}
.zip-search h2[data-v-189b2a9c] {
  margin-top: 1rem;
}
.zip-search h4[data-v-189b2a9c] {
  margin: 0 0 2rem;
}
.zip-search .box.flex[data-v-189b2a9c] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.zip-search .button[data-v-189b2a9c] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 4em;
  margin: 0 auto;
}
.zip-search .button.flex[data-v-189b2a9c] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.zip-search label.zip-code[data-v-189b2a9c] {
  font-weight: bold;
}
.zip-search input[type=tel][data-v-189b2a9c] {
  background-color: #ffffff !important;
  border: 1px solid #c3c3c3;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1em;
  height: 3em;
  padding: 0 0 0 1em;
  width: 100%;
  -moz-appearance: textfield;
}
.zip-search input[type=tel][data-v-189b2a9c]::-webkit-inner-spin-button, .zip-search input[type=tel][data-v-189b2a9c]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.zip-search input.has-error[data-v-189b2a9c] {
  border-right: 3px solid !important;
  border-color: red !important;
}
@media only screen and (min-width: 1400px) {
.zip-search[data-v-189b2a9c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 312px;
}
}
.input-group[data-v-189b2a9c] {
  width: 100%;
  margin: 0 0 1rem;
}
@media only screen and (min-width: 700px) {
.input-group[data-v-189b2a9c] {
      width: calc(50% - 12px);
}
}
@media only screen and (min-width: 1400px) {
.input-group[data-v-189b2a9c] {
      width: 100%;
}
}
.applicators[data-v-189b2a9c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
.applicators .applicator[data-v-189b2a9c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}
.applicators .applicator[data-v-189b2a9c]:nth-child(4) {
      margin-bottom: 0;
}
.applicators .applicator .header[data-v-189b2a9c] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #a00530;
      color: #ffffff;
      font-size: 1rem !important;
      padding: 0.875rem 1.25rem;
}
.applicators .applicator .header h3[data-v-189b2a9c] {
        font-family: 'trade-gothic-next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 1rem !important;
        font-style: normal;
        letter-spacing: 0.2px;
        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
}
.applicators .applicator .distance[data-v-189b2a9c] {
      background-color: #d6d8dc;
      border-bottom: 1px solid;
      border-color: #d6d8dc;
      font-size: 0.875rem;
      font-weight: bold;
      padding: 0.75rem 1.25rem;
}
.applicators .applicator .content[data-v-189b2a9c] {
      background-color: #ffffff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      padding: 1.25rem;
}
.applicators .applicator .content .item[data-v-189b2a9c] {
        margin: 1em 0 0 0;
        word-wrap: break-word;
}
.applicators .applicator .content .item[data-v-189b2a9c]:first-of-type {
          margin: 0;
}
.applicators .applicator .content .item p[data-v-189b2a9c] {
          margin: 0;
}
.applicators .applicator .content .item p.item-label[data-v-189b2a9c] {
            font-size: 0.875rem !important;
            font-weight: bold;
}
.applicators .applicator .content .item p a[data-v-189b2a9c] {
            font-weight: normal;
}
.applicators .applicator .content .item p a.link-email[data-v-189b2a9c] {
              text-decoration: underline;
}
.applicators .applicator .content .item-website[data-v-189b2a9c] {
        margin-top: auto;
}
.applicators .applicator .content .item-website a[data-v-189b2a9c] {
          margin-top: 1.25rem;
}
.applicators .applicator .links[data-v-189b2a9c] {
      color: #ffffff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 1em 0 .75em 0;
}
.applicators .applicator .links .link[data-v-189b2a9c] {
        color: #ffffff !important;
        font-weight: bold;
        font-size: .85em;
        margin: 0 1em;
        text-transform: uppercase;
}
@media only screen and (min-width: 700px) {
.applicators .applicator[data-v-189b2a9c] {
        width: calc(50% - 10px);
        margin-left: 20px;
}
.applicators .applicator[data-v-189b2a9c]:nth-child(odd) {
          margin-left: 0;
}
.applicators .applicator[data-v-189b2a9c]:nth-child(3) {
          margin-bottom: 0;
}
}
.results-pane[data-v-189b2a9c] {
  margin-top: 2rem;
  width: 100%;
}
.results-pane img[data-v-189b2a9c] {
    max-width: 100%;
    width: 648px;
}
@media only screen and (min-width: 1400px) {
.results-pane[data-v-189b2a9c] {
      margin-top: 0;
      max-width: 648px;
}
}

.app-loading[data-v-238cdc93] {
  text-align: center;
  z-index: 999;
}
.app-loading .mask[data-v-238cdc93] {
    width: 100%;
    height: 100%;
}
.app-loading .mask.active[data-v-238cdc93] {
      background-color: rgba(0, 0, 0, 0.65);
}
.app-loading.centered[data-v-238cdc93] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.app-loading .css-loader[data-v-238cdc93] {
    display: inline-block;
    width: 75px;
    height: 75px;
}
.app-loading .css-loader[data-v-238cdc93]:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 1px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #a00530 transparent #a00530 transparent;
      -webkit-animation: spin-data-v-238cdc93 1.2s linear infinite;
              animation: spin-data-v-238cdc93 1.2s linear infinite;
}
@-webkit-keyframes spin-data-v-238cdc93 {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@keyframes spin-data-v-238cdc93 {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
/* Mixins */
/* Imports */
@font-face {
  font-family: Entypo;
  src: url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.eot) format("eot"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.woff) format("woff"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.ttf) format("truetype");
}
#app h1[data-v-6886e2b1] {
  margin: .825em 0;
}
#app h1.thin[data-v-6886e2b1] {
    font-weight: normal !important;
}
#app h2.title[data-v-6886e2b1] {
  font-size: 1.5em;
  margin: .825em 0;
}
#app h2.title.thin[data-v-6886e2b1] {
    font-weight: normal !important;
}
#app h3[data-v-6886e2b1] {
  font-size: 1.17em;
  margin: .825em 0;
}
#app h3.thin[data-v-6886e2b1] {
    font-weight: normal !important;
}
#app p[data-v-6886e2b1] {
  font-size: 1em;
}
#app a.btn[data-v-6886e2b1], #app a.btn-link[data-v-6886e2b1] {
  cursor: pointer;
}
#app a.btn[data-v-6886e2b1]:hover, #app a.btn-link[data-v-6886e2b1]:hover {
    text-decoration: none;
}
.rrpxtend .main-content p[data-v-6886e2b1] {
  margin: 1em 0;
}
#app[data-v-6886e2b1] {
  /* <a> Links */
}
#app button.button[data-v-6886e2b1] {
    border: none;
}
#app .icon[data-v-6886e2b1] {
    background: transparent;
    border: none;
    margin: 0;
    cursor: pointer;
}
#app .icon img[data-v-6886e2b1] {
      vertical-align: middle;
      max-width: 25px;
}
#app .icon.large img[data-v-6886e2b1] {
      max-width: 25px;
}
#app .icon.medium img[data-v-6886e2b1] {
      max-width: 20px;
}
#app .icon.small img[data-v-6886e2b1] {
      max-width: 15px;
}
#app .button[data-v-6886e2b1] {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    padding: .5em 2em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: bold;
    font-style: italic;
    font-size: .80em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}
#app .button p[data-v-6886e2b1] {
      display: inline-block;
      -ms-flex-item-align: center;
          align-self: center;
      margin: 0;
}
#app .button[data-v-6886e2b1]:hover {
      text-decoration: none !important;
}
#app .button.small[data-v-6886e2b1] {
      height: 30px;
}
#app .button.medium[data-v-6886e2b1] {
      height: 40px;
}
#app .button.large[data-v-6886e2b1] {
      height: 50px;
}
#app .button.arrow.before[data-v-6886e2b1] {
      padding-left: 3em;
}
#app .button.arrow.after[data-v-6886e2b1] {
      padding-right: 3em;
}
#app .button.long[data-v-6886e2b1] {
      padding-left: 3em;
      padding-right: 3em;
}
#app .button.long.before[data-v-6886e2b1] {
        padding-left: 3.5em;
}
#app .button.long.after[data-v-6886e2b1] {
        padding-right: 3.5em;
}
#app .button.tall[data-v-6886e2b1] {
      padding-top: .75em;
      padding-bottom: .75em;
}
#app .button.grey[data-v-6886e2b1] {
      color: #555;
      background-color: #d9d7d7;
}
#app .button.red[data-v-6886e2b1] {
      color: #ffffff;
      background-color: #a00530;
}
#app .button.red[data-v-6886e2b1]:hover {
        background-color: #B70037;
}
#app .button.dark[data-v-6886e2b1] {
      color: #ffffff;
      background-color: #231f20;
}
#app .button.dark[data-v-6886e2b1]:hover {
        background-color: #393536;
}
#app .button.arrow.after[data-v-6886e2b1]:after {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      right: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.after.grey[data-v-6886e2b1]:after {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-dark.png);
}
#app .button.arrow.before[data-v-6886e2b1]:before {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      left: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.before.grey[data-v-6886e2b1]:before {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-dark.png);
}
#app .link[data-v-6886e2b1] {
    cursor: pointer;
    color: #2f2e2e;
}
#app .link.arrow.after[data-v-6886e2b1]:after {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-left: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.arrow.before[data-v-6886e2b1]:before {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-right: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.icon[data-v-6886e2b1] {
      text-decoration: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}
/* Inputs */
#app select[data-v-6886e2b1] {
  width: 100%;
}
/* vue-select styling */
#app .v-select[data-v-6886e2b1] {
  border: 1px solid #a8a8a9;
  background: transparent;
  color: #ffffff;
}
#app .v-select.unsearchable input[type=search][data-v-6886e2b1] {
    opacity: 1 !important;
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 0 !important;
}
#app .v-select[data-v-6886e2b1]:after {
    content: none;
}
#app .v-select.has-error input[type=search][data-v-6886e2b1]::-webkit-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-6886e2b1]:-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-6886e2b1]::-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-6886e2b1]:-ms-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select input[type=search][data-v-6886e2b1]::-webkit-input-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-6886e2b1]:-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-6886e2b1]::-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-6886e2b1]:-ms-input-placeholder {
    color: #d6d8dc;
}
#app .v-select .selected-tag[data-v-6886e2b1] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 1 !important;
    border: none;
    padding: 0 10px;
    margin: 0;
    width: 100%;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
#app .v-select .dropdown-toggle[data-v-6886e2b1] {
    height: 100%;
    border: none;
    padding: 0;
}
#app .v-select .dropdown-toggle .vs__selected-options[data-v-6886e2b1] {
      padding: 0;
}
#app .v-select .dropdown-toggle input[data-v-6886e2b1] {
      margin: 0;
}
#app .v-select .dropdown-menu[data-v-6886e2b1] {
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    border-left: 1px solid #f2f2f3;
    border-bottom: 1px solid #f2f2f3;
    border-right: 1px solid #f2f2f3;
    overflow-y: auto !important;
    z-index: 999;
    margin: 0;
    right: 0;
}
#app .v-select button.clear[data-v-6886e2b1] {
    display: none;
}
.v-select .dropdown-menu li[data-v-6886e2b1] {
  margin: 0 !important;
}
.v-select .dropdown-menu li[data-v-6886e2b1]:not(:last-of-type) {
    border-bottom: 1px solid #a8a8a9;
}
.v-select .dropdown-menu li.highlight a[data-v-6886e2b1] {
    background: #a00530;
    color: #ffffff;
}
.v-select .dropdown-menu li a[data-v-6886e2b1] {
    padding: 5px 10px;
    color: #2f2e2e;
}
.v-select .dropdown-menu li a[data-v-6886e2b1]:hover {
      text-decoration: none !important;
}
#app .box[data-v-6886e2b1] {
  background-color: #ffffff;
}
#app .box.grey[data-v-6886e2b1],
#app .bg-ltgrey .box.grey[data-v-6886e2b1] {
  background-color: #ebeef3;
}
#app .box.grey-dark[data-v-6886e2b1],
#app .bg-ltgrey .box.grey-dark[data-v-6886e2b1] {
  background-color: #d6d8dc;
}
#app .bg-ltgrey .box[data-v-6886e2b1],
#app .box.trans[data-v-6886e2b1] {
  background-color: transparent;
}
#app .flex[data-v-6886e2b1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .flex.split[data-v-6886e2b1] {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
#app .flex.center.horizontal[data-v-6886e2b1] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#app .flex.center.vertical[data-v-6886e2b1] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
#app .flex.wrap[data-v-6886e2b1] {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#app .flex.wrap.reverse[data-v-6886e2b1] {
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
}
.rrpxtend #wrapper #main > .inner[data-v-6886e2b1] {
  padding: 0 !important;
}
/* Core */
#app[data-v-6886e2b1] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app .bordered.full[data-v-6886e2b1] {
    border: 1px solid;
}
#app .bordered.top[data-v-6886e2b1] {
    border-top: 5px solid;
}
#app .bordered.red[data-v-6886e2b1] {
    border-color: #a00530;
}
#app .bordered.grey[data-v-6886e2b1] {
    border-color: #ebeef3;
}
#app .bordered.grey.dark[data-v-6886e2b1] {
      border-color: #d6d8dc;
}
#app .color.red[data-v-6886e2b1] {
    color: #a00530;
}
#app .floated[data-v-6886e2b1] {
    position: absolute;
}
#app .floated.top[data-v-6886e2b1] {
      top: 1em;
}
#app .floated.right[data-v-6886e2b1] {
      right: 1em;
}
#app .section-separator[data-v-6886e2b1] {
    border-width: 3px;
    border-style: solid;
    border-color: #ebeef3;
}
.app-confirmation h1[data-v-6886e2b1] {
  margin-top: 0 !important;
}
/* Mixins */
/* Imports */
@font-face {
  font-family: Entypo;
  src: url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.eot) format("eot"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.woff) format("woff"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.ttf) format("truetype");
}
#app h1[data-v-57bb38e4] {
  margin: .825em 0;
}
#app h1.thin[data-v-57bb38e4] {
    font-weight: normal !important;
}
#app h2.title[data-v-57bb38e4] {
  font-size: 1.5em;
  margin: .825em 0;
}
#app h2.title.thin[data-v-57bb38e4] {
    font-weight: normal !important;
}
#app h3[data-v-57bb38e4] {
  font-size: 1.17em;
  margin: .825em 0;
}
#app h3.thin[data-v-57bb38e4] {
    font-weight: normal !important;
}
#app p[data-v-57bb38e4] {
  font-size: 1em;
}
#app a.btn[data-v-57bb38e4], #app a.btn-link[data-v-57bb38e4] {
  cursor: pointer;
}
#app a.btn[data-v-57bb38e4]:hover, #app a.btn-link[data-v-57bb38e4]:hover {
    text-decoration: none;
}
.rrpxtend .main-content p[data-v-57bb38e4] {
  margin: 1em 0;
}
#app[data-v-57bb38e4] {
  /* <a> Links */
}
#app button.button[data-v-57bb38e4] {
    border: none;
}
#app .icon[data-v-57bb38e4] {
    background: transparent;
    border: none;
    margin: 0;
    cursor: pointer;
}
#app .icon img[data-v-57bb38e4] {
      vertical-align: middle;
      max-width: 25px;
}
#app .icon.large img[data-v-57bb38e4] {
      max-width: 25px;
}
#app .icon.medium img[data-v-57bb38e4] {
      max-width: 20px;
}
#app .icon.small img[data-v-57bb38e4] {
      max-width: 15px;
}
#app .button[data-v-57bb38e4] {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    padding: .5em 2em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: bold;
    font-style: italic;
    font-size: .80em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}
#app .button p[data-v-57bb38e4] {
      display: inline-block;
      -ms-flex-item-align: center;
          align-self: center;
      margin: 0;
}
#app .button[data-v-57bb38e4]:hover {
      text-decoration: none !important;
}
#app .button.small[data-v-57bb38e4] {
      height: 30px;
}
#app .button.medium[data-v-57bb38e4] {
      height: 40px;
}
#app .button.large[data-v-57bb38e4] {
      height: 50px;
}
#app .button.arrow.before[data-v-57bb38e4] {
      padding-left: 3em;
}
#app .button.arrow.after[data-v-57bb38e4] {
      padding-right: 3em;
}
#app .button.long[data-v-57bb38e4] {
      padding-left: 3em;
      padding-right: 3em;
}
#app .button.long.before[data-v-57bb38e4] {
        padding-left: 3.5em;
}
#app .button.long.after[data-v-57bb38e4] {
        padding-right: 3.5em;
}
#app .button.tall[data-v-57bb38e4] {
      padding-top: .75em;
      padding-bottom: .75em;
}
#app .button.grey[data-v-57bb38e4] {
      color: #555;
      background-color: #d9d7d7;
}
#app .button.red[data-v-57bb38e4] {
      color: #ffffff;
      background-color: #a00530;
}
#app .button.red[data-v-57bb38e4]:hover {
        background-color: #B70037;
}
#app .button.dark[data-v-57bb38e4] {
      color: #ffffff;
      background-color: #231f20;
}
#app .button.dark[data-v-57bb38e4]:hover {
        background-color: #393536;
}
#app .button.arrow.after[data-v-57bb38e4]:after {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      right: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.after.grey[data-v-57bb38e4]:after {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-dark.png);
}
#app .button.arrow.before[data-v-57bb38e4]:before {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      left: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.before.grey[data-v-57bb38e4]:before {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-dark.png);
}
#app .link[data-v-57bb38e4] {
    cursor: pointer;
    color: #2f2e2e;
}
#app .link.arrow.after[data-v-57bb38e4]:after {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-left: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.arrow.before[data-v-57bb38e4]:before {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-right: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.icon[data-v-57bb38e4] {
      text-decoration: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}
/* Inputs */
#app select[data-v-57bb38e4] {
  width: 100%;
}
/* vue-select styling */
#app .v-select[data-v-57bb38e4] {
  border: 1px solid #a8a8a9;
  background: transparent;
  color: #ffffff;
}
#app .v-select.unsearchable input[type=search][data-v-57bb38e4] {
    opacity: 1 !important;
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 0 !important;
}
#app .v-select[data-v-57bb38e4]:after {
    content: none;
}
#app .v-select.has-error input[type=search][data-v-57bb38e4]::-webkit-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-57bb38e4]:-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-57bb38e4]::-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-57bb38e4]:-ms-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select input[type=search][data-v-57bb38e4]::-webkit-input-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-57bb38e4]:-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-57bb38e4]::-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-57bb38e4]:-ms-input-placeholder {
    color: #d6d8dc;
}
#app .v-select .selected-tag[data-v-57bb38e4] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 1 !important;
    border: none;
    padding: 0 10px;
    margin: 0;
    width: 100%;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
#app .v-select .dropdown-toggle[data-v-57bb38e4] {
    height: 100%;
    border: none;
    padding: 0;
}
#app .v-select .dropdown-toggle .vs__selected-options[data-v-57bb38e4] {
      padding: 0;
}
#app .v-select .dropdown-toggle input[data-v-57bb38e4] {
      margin: 0;
}
#app .v-select .dropdown-menu[data-v-57bb38e4] {
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    border-left: 1px solid #f2f2f3;
    border-bottom: 1px solid #f2f2f3;
    border-right: 1px solid #f2f2f3;
    overflow-y: auto !important;
    z-index: 999;
    margin: 0;
    right: 0;
}
#app .v-select button.clear[data-v-57bb38e4] {
    display: none;
}
.v-select .dropdown-menu li[data-v-57bb38e4] {
  margin: 0 !important;
}
.v-select .dropdown-menu li[data-v-57bb38e4]:not(:last-of-type) {
    border-bottom: 1px solid #a8a8a9;
}
.v-select .dropdown-menu li.highlight a[data-v-57bb38e4] {
    background: #a00530;
    color: #ffffff;
}
.v-select .dropdown-menu li a[data-v-57bb38e4] {
    padding: 5px 10px;
    color: #2f2e2e;
}
.v-select .dropdown-menu li a[data-v-57bb38e4]:hover {
      text-decoration: none !important;
}
#app .box[data-v-57bb38e4] {
  background-color: #ffffff;
}
#app .box.grey[data-v-57bb38e4],
#app .bg-ltgrey .box.grey[data-v-57bb38e4] {
  background-color: #ebeef3;
}
#app .box.grey-dark[data-v-57bb38e4],
#app .bg-ltgrey .box.grey-dark[data-v-57bb38e4] {
  background-color: #d6d8dc;
}
#app .bg-ltgrey .box[data-v-57bb38e4],
#app .box.trans[data-v-57bb38e4] {
  background-color: transparent;
}
#app .flex[data-v-57bb38e4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .flex.split[data-v-57bb38e4] {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
#app .flex.center.horizontal[data-v-57bb38e4] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#app .flex.center.vertical[data-v-57bb38e4] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
#app .flex.wrap[data-v-57bb38e4] {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#app .flex.wrap.reverse[data-v-57bb38e4] {
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
}
.rrpxtend #wrapper #main > .inner[data-v-57bb38e4] {
  padding: 0 !important;
}
/* Core */
#app[data-v-57bb38e4] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app .bordered.full[data-v-57bb38e4] {
    border: 1px solid;
}
#app .bordered.top[data-v-57bb38e4] {
    border-top: 5px solid;
}
#app .bordered.red[data-v-57bb38e4] {
    border-color: #a00530;
}
#app .bordered.grey[data-v-57bb38e4] {
    border-color: #ebeef3;
}
#app .bordered.grey.dark[data-v-57bb38e4] {
      border-color: #d6d8dc;
}
#app .color.red[data-v-57bb38e4] {
    color: #a00530;
}
#app .floated[data-v-57bb38e4] {
    position: absolute;
}
#app .floated.top[data-v-57bb38e4] {
      top: 1em;
}
#app .floated.right[data-v-57bb38e4] {
      right: 1em;
}
#app .section-separator[data-v-57bb38e4] {
    border-width: 3px;
    border-style: solid;
    border-color: #ebeef3;
}
.section-headline h2[data-v-57bb38e4] {
  margin: 0;
  font-weight: normal;
}
.section-headline p[data-v-57bb38e4] {
  margin: 1em 0;
}
.zip-search h4[data-v-57bb38e4] {
  margin: .25em 0;
}
.zip-search .box.flex[data-v-57bb38e4] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.zip-search .button[data-v-57bb38e4] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 4em;
  margin: 0 auto;
}
.zip-search .button.flex[data-v-57bb38e4] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.zip-search input[type=tel][data-v-57bb38e4] {
  height: 3em;
  width: 100%;
  padding: 0 0 0 1em;
  font-weight: bold;
  border: 1px solid #c3c3c3;
  font-size: 1em;
  -moz-appearance: textfield;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.zip-search input[type=tel][data-v-57bb38e4]::-webkit-inner-spin-button, .zip-search input[type=tel][data-v-57bb38e4]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.zip-search input.has-error[data-v-57bb38e4] {
  border-right: 3px solid !important;
  border-color: red !important;
}
.input-group[data-v-57bb38e4] {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 1em;
  font-size: 1.25em;
}
@media only screen and (min-width: 600px) {
.result-header[data-v-57bb38e4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.result-header > div[data-v-57bb38e4] {
      width: calc(50% - 10px);
}
}
.section-headline p[data-v-57bb38e4] {
  font-size: 1em !important;
}
.result-filter[data-v-57bb38e4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 1em 0;
}
.result-filter > div[data-v-57bb38e4] {
    width: calc(50% - 10px);
}
.result-filter select[data-v-57bb38e4] {
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    -webkit-box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
            box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23A00530%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e5e5e5));
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23A00530%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}
.result-filter select[data-v-57bb38e4]::-ms-expand {
    display: none;
}
.result-filter select[data-v-57bb38e4]:hover {
    border-color: #888;
}
.result-filter select[data-v-57bb38e4]:focus {
    border-color: #aaa;
    -webkit-box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
            box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}
.result-filter select option[data-v-57bb38e4] {
    font-weight: normal;
}
.legal[data-v-57bb38e4] {
  font-size: 12px;
  line-height: 1.5em !important;
  margin: 2rem 0;
}
.applicators[data-v-57bb38e4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 20px;
}
.applicators .applicator[data-v-57bb38e4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}
.applicators .applicator .header[data-v-57bb38e4] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #a00530;
      color: #ffffff;
      font-size: 1em !important;
      padding: 0.5em 2em;
}
.applicators .applicator .header h3[data-v-57bb38e4] {
        padding-left: 1em;
        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
}
.applicators .applicator .header img[data-v-57bb38e4] {
        max-width: 50px;
}
.applicators .applicator .distance[data-v-57bb38e4] {
      background-color: #d6d8dc;
      border-bottom: 1px solid;
      border-color: #d6d8dc;
      padding: 0.5em 1em;
}
.applicators .applicator .content[data-v-57bb38e4] {
      padding: 1em 2em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
}
.applicators .applicator .content .item[data-v-57bb38e4] {
        margin: 1em 0 0 0;
        word-wrap: break-word;
}
.applicators .applicator .content .item[data-v-57bb38e4]:first-of-type {
          margin: 0;
}
.applicators .applicator .content .item p[data-v-57bb38e4] {
          margin: 0;
}
.applicators .applicator .content .item-website[data-v-57bb38e4] {
        margin-top: auto;
}
.applicators .applicator .content .item-website a[data-v-57bb38e4] {
          margin-top: 1em;
}
.applicators .applicator .links[data-v-57bb38e4] {
      color: #ffffff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 1em 0 .75em 0;
}
.applicators .applicator .links .link[data-v-57bb38e4] {
        color: #ffffff !important;
        font-weight: bold;
        font-size: .85em;
        margin: 0 1em;
        text-transform: uppercase;
}
@media only screen and (min-width: 700px) {
.applicators .applicator[data-v-57bb38e4] {
        width: calc(50% - 12px);
        margin-left: 20px;
}
.applicators .applicator[data-v-57bb38e4]:nth-child(odd) {
          margin-left: 0;
}
}
@media only screen and (min-width: 1400px) {
.applicators .applicator[data-v-57bb38e4] {
        width: calc(33.333% - 15.34px);
}
.applicators .applicator[data-v-57bb38e4]:nth-child(odd) {
          margin-left: 20px;
}
.applicators .applicator[data-v-57bb38e4]:nth-child(3n+1) {
          margin-left: 0;
}
}
.loading[data-v-57bb38e4] {
  margin-bottom: 25px;
}
.apply[data-v-57bb38e4] {
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 1.2;
}
.apply .content[data-v-57bb38e4] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1em 2em;
    width: 100%;
}
@media only screen and (max-width: 600px) {
.apply .content[data-v-57bb38e4] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: .5em 1em;
}
.apply .content .button[data-v-57bb38e4] {
          height: 40px;
}
}
.remove[data-v-57bb38e4] {
  padding: 1em 1em 0;
  text-align: center;
}

.nav-row[data-v-f8efd7f4] {
  margin: 1em auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.nav-row a[data-v-f8efd7f4] {
    padding: 5px 10px;
    margin: 0 0.5em;
    cursor: pointer;
}
.nav-row a.active[data-v-f8efd7f4] {
      background-color: #ddd;
      font-weight: bolder;
}
.nav-row .previous[data-v-f8efd7f4], .nav-row .next[data-v-f8efd7f4] {
    width: 5em;
    display: inline-block;
    text-align: center;
}
/* Mixins */
/* Imports */
@font-face {
  font-family: Entypo;
  src: url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.eot) format("eot"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.woff) format("woff"), url(https://hlk-apps.prvsn.net/find-applicator/css/fonts/entypo.ttf) format("truetype");
}
#app h1[data-v-0aba7f40] {
  margin: .825em 0;
}
#app h1.thin[data-v-0aba7f40] {
    font-weight: normal !important;
}
#app h2.title[data-v-0aba7f40] {
  font-size: 1.5em;
  margin: .825em 0;
}
#app h2.title.thin[data-v-0aba7f40] {
    font-weight: normal !important;
}
#app h3[data-v-0aba7f40] {
  font-size: 1.17em;
  margin: .825em 0;
}
#app h3.thin[data-v-0aba7f40] {
    font-weight: normal !important;
}
#app p[data-v-0aba7f40] {
  font-size: 1em;
}
#app a.btn[data-v-0aba7f40], #app a.btn-link[data-v-0aba7f40] {
  cursor: pointer;
}
#app a.btn[data-v-0aba7f40]:hover, #app a.btn-link[data-v-0aba7f40]:hover {
    text-decoration: none;
}
.rrpxtend .main-content p[data-v-0aba7f40] {
  margin: 1em 0;
}
#app[data-v-0aba7f40] {
  /* <a> Links */
}
#app button.button[data-v-0aba7f40] {
    border: none;
}
#app .icon[data-v-0aba7f40] {
    background: transparent;
    border: none;
    margin: 0;
    cursor: pointer;
}
#app .icon img[data-v-0aba7f40] {
      vertical-align: middle;
      max-width: 25px;
}
#app .icon.large img[data-v-0aba7f40] {
      max-width: 25px;
}
#app .icon.medium img[data-v-0aba7f40] {
      max-width: 20px;
}
#app .icon.small img[data-v-0aba7f40] {
      max-width: 15px;
}
#app .button[data-v-0aba7f40] {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    padding: .5em 2em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: bold;
    font-style: italic;
    font-size: .80em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all .2s;
    transition: all .2s;
}
#app .button p[data-v-0aba7f40] {
      display: inline-block;
      -ms-flex-item-align: center;
          align-self: center;
      margin: 0;
}
#app .button[data-v-0aba7f40]:hover {
      text-decoration: none !important;
}
#app .button.small[data-v-0aba7f40] {
      height: 30px;
}
#app .button.medium[data-v-0aba7f40] {
      height: 40px;
}
#app .button.large[data-v-0aba7f40] {
      height: 50px;
}
#app .button.arrow.before[data-v-0aba7f40] {
      padding-left: 3em;
}
#app .button.arrow.after[data-v-0aba7f40] {
      padding-right: 3em;
}
#app .button.long[data-v-0aba7f40] {
      padding-left: 3em;
      padding-right: 3em;
}
#app .button.long.before[data-v-0aba7f40] {
        padding-left: 3.5em;
}
#app .button.long.after[data-v-0aba7f40] {
        padding-right: 3.5em;
}
#app .button.tall[data-v-0aba7f40] {
      padding-top: .75em;
      padding-bottom: .75em;
}
#app .button.grey[data-v-0aba7f40] {
      color: #555;
      background-color: #d9d7d7;
}
#app .button.red[data-v-0aba7f40] {
      color: #ffffff;
      background-color: #a00530;
}
#app .button.red[data-v-0aba7f40]:hover {
        background-color: #B70037;
}
#app .button.dark[data-v-0aba7f40] {
      color: #ffffff;
      background-color: #231f20;
}
#app .button.dark[data-v-0aba7f40]:hover {
        background-color: #393536;
}
#app .button.arrow.after[data-v-0aba7f40]:after {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      right: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.after.grey[data-v-0aba7f40]:after {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-right-dark.png);
}
#app .button.arrow.before[data-v-0aba7f40]:before {
      content: '';
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-med.png);
      background-repeat: no-repeat;
      background-size: 8px 8px;
      position: absolute;
      margin: auto 0;
      left: 15px;
      bottom: 0;
      top: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
}
#app .button.arrow.before.grey[data-v-0aba7f40]:before {
      background-image: url(http://www.roundupreadyxtend.com/Style%20Library/roundupreadyxtend/img/global/icons/arrow-left-dark.png);
}
#app .link[data-v-0aba7f40] {
    cursor: pointer;
    color: #2f2e2e;
}
#app .link.arrow.after[data-v-0aba7f40]:after {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-left: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.arrow.before[data-v-0aba7f40]:before {
      font-family: "Entypo";
      font-size: 1.75em;
      line-height: 0.5em;
      margin-right: 0.15em;
      position: relative;
      top: 0.1em;
      display: inline-block;
      content: attr(data-icon);
}
#app .link.icon[data-v-0aba7f40] {
      text-decoration: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}
/* Inputs */
#app select[data-v-0aba7f40] {
  width: 100%;
}
/* vue-select styling */
#app .v-select[data-v-0aba7f40] {
  border: 1px solid #a8a8a9;
  background: transparent;
  color: #ffffff;
}
#app .v-select.unsearchable input[type=search][data-v-0aba7f40] {
    opacity: 1 !important;
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 0 !important;
}
#app .v-select[data-v-0aba7f40]:after {
    content: none;
}
#app .v-select.has-error input[type=search][data-v-0aba7f40]::-webkit-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-0aba7f40]:-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-0aba7f40]::-moz-placeholder {
    color: #ff0000 !important;
}
#app .v-select.has-error input[type=search][data-v-0aba7f40]:-ms-input-placeholder {
    color: #ff0000 !important;
}
#app .v-select input[type=search][data-v-0aba7f40]::-webkit-input-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-0aba7f40]:-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-0aba7f40]::-moz-placeholder {
    color: #d6d8dc;
}
#app .v-select input[type=search][data-v-0aba7f40]:-ms-input-placeholder {
    color: #d6d8dc;
}
#app .v-select .selected-tag[data-v-0aba7f40] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 1 !important;
    border: none;
    padding: 0 10px;
    margin: 0;
    width: 100%;
    overflow: hidden;
    display: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
#app .v-select .dropdown-toggle[data-v-0aba7f40] {
    height: 100%;
    border: none;
    padding: 0;
}
#app .v-select .dropdown-toggle .vs__selected-options[data-v-0aba7f40] {
      padding: 0;
}
#app .v-select .dropdown-toggle input[data-v-0aba7f40] {
      margin: 0;
}
#app .v-select .dropdown-menu[data-v-0aba7f40] {
    border-radius: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    border-left: 1px solid #f2f2f3;
    border-bottom: 1px solid #f2f2f3;
    border-right: 1px solid #f2f2f3;
    overflow-y: auto !important;
    z-index: 999;
    margin: 0;
    right: 0;
}
#app .v-select button.clear[data-v-0aba7f40] {
    display: none;
}
.v-select .dropdown-menu li[data-v-0aba7f40] {
  margin: 0 !important;
}
.v-select .dropdown-menu li[data-v-0aba7f40]:not(:last-of-type) {
    border-bottom: 1px solid #a8a8a9;
}
.v-select .dropdown-menu li.highlight a[data-v-0aba7f40] {
    background: #a00530;
    color: #ffffff;
}
.v-select .dropdown-menu li a[data-v-0aba7f40] {
    padding: 5px 10px;
    color: #2f2e2e;
}
.v-select .dropdown-menu li a[data-v-0aba7f40]:hover {
      text-decoration: none !important;
}
#app .box[data-v-0aba7f40] {
  background-color: #ffffff;
}
#app .box.grey[data-v-0aba7f40],
#app .bg-ltgrey .box.grey[data-v-0aba7f40] {
  background-color: #ebeef3;
}
#app .box.grey-dark[data-v-0aba7f40],
#app .bg-ltgrey .box.grey-dark[data-v-0aba7f40] {
  background-color: #d6d8dc;
}
#app .bg-ltgrey .box[data-v-0aba7f40],
#app .box.trans[data-v-0aba7f40] {
  background-color: transparent;
}
#app .flex[data-v-0aba7f40] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .flex.split[data-v-0aba7f40] {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
#app .flex.center.horizontal[data-v-0aba7f40] {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#app .flex.center.vertical[data-v-0aba7f40] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
#app .flex.wrap[data-v-0aba7f40] {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#app .flex.wrap.reverse[data-v-0aba7f40] {
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
}
.rrpxtend #wrapper #main > .inner[data-v-0aba7f40] {
  padding: 0 !important;
}
/* Core */
#app[data-v-0aba7f40] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app .bordered.full[data-v-0aba7f40] {
    border: 1px solid;
}
#app .bordered.top[data-v-0aba7f40] {
    border-top: 5px solid;
}
#app .bordered.red[data-v-0aba7f40] {
    border-color: #a00530;
}
#app .bordered.grey[data-v-0aba7f40] {
    border-color: #ebeef3;
}
#app .bordered.grey.dark[data-v-0aba7f40] {
      border-color: #d6d8dc;
}
#app .color.red[data-v-0aba7f40] {
    color: #a00530;
}
#app .floated[data-v-0aba7f40] {
    position: absolute;
}
#app .floated.top[data-v-0aba7f40] {
      top: 1em;
}
#app .floated.right[data-v-0aba7f40] {
      right: 1em;
}
#app .section-separator[data-v-0aba7f40] {
    border-width: 3px;
    border-style: solid;
    border-color: #ebeef3;
}
.app-forms[data-v-0aba7f40] {
  position: relative;
}
.app-popup[data-v-0aba7f40] {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  margin: auto;
}
.app-popup .mask[data-v-0aba7f40] {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
}
.app-popup .mask .window[data-v-0aba7f40] {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background-color: #ffffff;
      max-width: 728px;
      padding: 1em 3em;
      margin: 0 auto;
      width: 100vw;
      max-height: 90vh;
      overflow-y: auto;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.app-popup .mask .window a.icon[data-v-0aba7f40] {
        position: absolute;
        top: 10px;
        right: 10px;
}
@media only screen and (max-width: 728px) {
.app-popup .mask .window[data-v-0aba7f40] {
          width: 100%;
          padding: .5em 2em;
}
}
.app-form[data-v-0aba7f40] {
  width: 100%;
  max-width: 98%;
}
.app-form .input-group[data-v-0aba7f40] {
    margin-top: 20px !important;
}
.app-form .input-group .input[data-v-0aba7f40] {
      width: 49%;
      margin: 0;
}
.app-form .input[data-v-0aba7f40] {
    width: 47.5%;
    display: inline-block;
    margin: 0;
}
.app-form .input label[data-v-0aba7f40] {
      margin-top: 10px;
}
.app-form .input.full[data-v-0aba7f40] {
      width: 100%;
}
.app-form .input.full h4[data-v-0aba7f40] {
        margin-top: 15px !important;
}
.app-form .input h4[data-v-0aba7f40] {
      margin: 0 !important;
      font-size: .9rem !important;
}
.app-form select[data-v-0aba7f40] {
    height: 45px;
}
.app-form input[type=text][data-v-0aba7f40], .app-form input[type=number][data-v-0aba7f40], .app-form input[type=tel][data-v-0aba7f40], .app-form input[type=email][data-v-0aba7f40], .app-form .v-select[data-v-0aba7f40] {
    width: 100%;
    margin: 1px 0;
    height: 45px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -moz-appearance: textfield;
}
.app-form input[type=text][data-v-0aba7f40]::-webkit-input-placeholder, .app-form input[type=number][data-v-0aba7f40]::-webkit-input-placeholder, .app-form input[type=tel][data-v-0aba7f40]::-webkit-input-placeholder, .app-form input[type=email][data-v-0aba7f40]::-webkit-input-placeholder, .app-form .v-select[data-v-0aba7f40]::-webkit-input-placeholder {
      color: #d6d8dc;
}
.app-form input[type=text][data-v-0aba7f40]:-moz-placeholder, .app-form input[type=number][data-v-0aba7f40]:-moz-placeholder, .app-form input[type=tel][data-v-0aba7f40]:-moz-placeholder, .app-form input[type=email][data-v-0aba7f40]:-moz-placeholder, .app-form .v-select[data-v-0aba7f40]:-moz-placeholder {
      color: #d6d8dc;
}
.app-form input[type=text][data-v-0aba7f40]::-moz-placeholder, .app-form input[type=number][data-v-0aba7f40]::-moz-placeholder, .app-form input[type=tel][data-v-0aba7f40]::-moz-placeholder, .app-form input[type=email][data-v-0aba7f40]::-moz-placeholder, .app-form .v-select[data-v-0aba7f40]::-moz-placeholder {
      color: #d6d8dc;
}
.app-form input[type=text][data-v-0aba7f40]:-ms-input-placeholder, .app-form input[type=number][data-v-0aba7f40]:-ms-input-placeholder, .app-form input[type=tel][data-v-0aba7f40]:-ms-input-placeholder, .app-form input[type=email][data-v-0aba7f40]:-ms-input-placeholder, .app-form .v-select[data-v-0aba7f40]:-ms-input-placeholder {
      color: #d6d8dc;
}
.app-form input[type=text][data-v-0aba7f40]::-webkit-inner-spin-button, .app-form input[type=text][data-v-0aba7f40]::-webkit-outer-spin-button, .app-form input[type=number][data-v-0aba7f40]::-webkit-inner-spin-button, .app-form input[type=number][data-v-0aba7f40]::-webkit-outer-spin-button, .app-form input[type=tel][data-v-0aba7f40]::-webkit-inner-spin-button, .app-form input[type=tel][data-v-0aba7f40]::-webkit-outer-spin-button, .app-form input[type=email][data-v-0aba7f40]::-webkit-inner-spin-button, .app-form input[type=email][data-v-0aba7f40]::-webkit-outer-spin-button, .app-form .v-select[data-v-0aba7f40]::-webkit-inner-spin-button, .app-form .v-select[data-v-0aba7f40]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
}
.app-form input[type=text][data-v-0aba7f40]:-ms-input-placeholder, .app-form input[type=text][data-v-0aba7f40]::-ms-input-placeholder, .app-form input[type=number][data-v-0aba7f40]:-ms-input-placeholder, .app-form input[type=number][data-v-0aba7f40]::-ms-input-placeholder, .app-form input[type=tel][data-v-0aba7f40]:-ms-input-placeholder, .app-form input[type=tel][data-v-0aba7f40]::-ms-input-placeholder, .app-form input[type=email][data-v-0aba7f40]:-ms-input-placeholder, .app-form input[type=email][data-v-0aba7f40]::-ms-input-placeholder, .app-form .v-select[data-v-0aba7f40]:-ms-input-placeholder, .app-form .v-select[data-v-0aba7f40]::-ms-input-placeholder {
      color: #ebeef3;
}
.app-form .error-text[data-v-0aba7f40] {
    text-align: center;
    margin-bottom: 0;
}
.app-form .input-submit[data-v-0aba7f40] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 1em 0;
}
.app-form .input-submit button.button[data-v-0aba7f40] {
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      font-size: 16px;
}
@media only screen and (max-width: 600px) {
.app-form .input-submit[data-v-0aba7f40] {
        display: block;
        margin: 1em auto;
        text-align: center;
}
}
.app-form input.has-error[data-v-0aba7f40], .app-form .v-select.has-error[data-v-0aba7f40] {
    border-right: 3px solid !important;
    border-color: #ff0000 !important;
}
.app-form input.has-error[data-v-0aba7f40]::-webkit-input-placeholder, .app-form .v-select.has-error[data-v-0aba7f40]::-webkit-input-placeholder {
      color: #ff0000 !important;
}
.app-form input.has-error[data-v-0aba7f40]:-moz-placeholder, .app-form .v-select.has-error[data-v-0aba7f40]:-moz-placeholder {
      color: #ff0000 !important;
}
.app-form input.has-error[data-v-0aba7f40]::-moz-placeholder, .app-form .v-select.has-error[data-v-0aba7f40]::-moz-placeholder {
      color: #ff0000 !important;
}
.app-form input.has-error[data-v-0aba7f40]:-ms-input-placeholder, .app-form .v-select.has-error[data-v-0aba7f40]:-ms-input-placeholder {
      color: #ff0000 !important;
}
.app-form .error-text[data-v-0aba7f40] {
    color: #ff0000;
}
